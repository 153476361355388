import { baseEncode64 } from "@/utils/string";
import { translate } from "vue-gettext";
const { gettext: $gettext } = translate;

const ColumnType = Object.freeze({
  Number: "number",
  String: "string",
  Date: "date",
  Id: "id",
  JsonArrayAgg: "json_array_agg"
});

const arithmeticableTypes = [ColumnType.Number, ColumnType.Date];

export const Operator = Object.freeze({
  Equal: {
    value: "=",
    types: Object.values(ColumnType),
    get label() {
      return $gettext("is equal to");
    }
  },
  NotEqual: {
    value: "!=",
    types: Object.values(ColumnType),
    get label() {
      return $gettext("is not equal to");
    }
  },
  GT: {
    value: ">",
    types: arithmeticableTypes,
    get label() {
      return $gettext("is greater than");
    }
  },
  GTE: {
    value: ">=",
    types: arithmeticableTypes,
    get label() {
      return $gettext("is greater than or equal to");
    }
  },
  LT: {
    value: "<",
    types: arithmeticableTypes,
    get label() {
      return $gettext("is less than");
    }
  },
  LTE: {
    value: "<=",
    types: arithmeticableTypes,
    get label() {
      return $gettext("is less than or equal to");
    }
  },
  Between: {
    value: "between",
    types: arithmeticableTypes,
    get label() {
      return $gettext("is between");
    }
  }
});

export const OperatorByValue = Object.entries(Operator).reduce(
  (map, column) => {
    map[column[1].value] = column[1];
    return map;
  },
  {}
);

export const toString = request => {
  return baseEncode64(JSON.stringify(request));
};

export const requestFilterToFilters = (requestFilters, schema) => {
  const mappedSchema = schema.reduce((map, column) => {
    map[column.name] = column;
    return map;
  }, {});
  return requestFilters.map(requestFilter => {
    const filter = createFilter();
    filter.setColumn(mappedSchema[requestFilter["field"]]);
    filter.setOperator(OperatorByValue[requestFilter.operator]);
    filter.value = requestFilter.value;
    return filter;
  });
};
export const filtersToRequest = filters =>
  filters.map(filter => filter.toRequest());

const createColumn = column => {
  return Object.assign(
    {
      get isEmpty() {
        return this.name === undefined;
      },
      get isOption() {
        return !this.isEmpty && this.options !== null;
      },
      get isValuesOption() {
        return this.isOption && this.options.type === "values";
      },
      get isEndpointOption() {
        return this.isOption && this.options.type === "endpoint";
      }
    },
    column
  );
};

export const createFilter = () => {
  return Object.assign(
    {
      get disabledInput() {
        return this.column.isEmpty;
      },
      get disabledOperator() {
        return this.column.isEmpty;
      },
      get operatorOptions() {
        return Object.values(Operator).filter(
          operator =>
            this.column.isEmpty || operator.types.includes(this.column.type)
        );
      },
      get showMultiselect() {
        return (
          this.column.isEmpty ||
          [
            ColumnType.String,
            ColumnType.Number,
            ColumnType.Id,
            ColumnType.JsonArrayAgg
          ].includes(this.column.type)
        );
      },
      get showDatePicker() {
        return (
          !this.column.isEmpty &&
          this.column.type === ColumnType.Date &&
          this.operator.value !== Operator.Between.value
        );
      },
      get showDateRangePicker() {
        return (
          !this.column.isEmpty &&
          this.column.type === ColumnType.Date &&
          this.operator.value === Operator.Between.value
        );
      }
    },
    {
      id: crypto.randomUUID(),
      column: createColumn({}),
      operator: Operator.Equal,
      previousOperator: Operator.Equal,
      value: [],
      options: [],
      setColumn(column) {
        this.column = createColumn(column);
        this.initOperatorValue();
        this.initInputOptions();
        this.initInputValue();
      },
      setOperator(operator) {
        this.operator = operator;
        if (
          [this.previousOperator?.value, this.operator?.value].includes(
            Operator.Between.value
          )
        ) {
          this.initInputValue();
        }
        this.previousOperator = this.operator;
      },
      initOperatorValue() {
        switch (true) {
          case this.column.isEmpty:
          case !this.operator.types.includes(this.column.type):
            this.operator = Operator.Equal;
        }
      },
      initInputValue() {
        switch (true) {
          case this.column.isOption:
            this.value = [];
            break;
          case this.column.type === ColumnType.Date &&
            this.operator.value === Operator.Between.value:
            this.value = { start: null, end: null };
            break;
          default:
            this.value = null;
        }
      },
      initInputOptions() {
        switch (true) {
          case this.column.isValuesOption:
            this.options = this.column.options.values;
            break;
          default:
            this.options = [];
        }
      },
      toRequest() {
        return {
          field: this.column.name || null,
          operator: this?.operator?.value || null,
          value: this.value || null
        };
      }
    }
  );
};
